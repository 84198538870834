/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  questions: [],
};

const questionDatabaseSlice = createSlice({
  name: 'questionDatabase',
  initialState,
  reducers: {},
  extraReducers: {},
});

export default questionDatabaseSlice.reducer;
