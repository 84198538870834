/* eslint-disable import/no-cycle */
import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import auth from './auth';
import themeLayout from './themeLayout';
import user from './user';
import materials from './materials';
import students from './students';
import courses from './courses';
import tickets from './tickets';
import coupons from './coupons';
import products from './products';
import cart from './cart';
import orders from './orders';
import server from './server';
import rewards from './rewards';
import quiz from './quiz';
import crm from './crm';
import quizquestions from './quizquestions';
import multimedia from './multimedia';
import materialVideos from './materialVideos';
import accesscodes from './accesscodes';
import ratings from './ratings';
import warnings from './warnings';
import userTokens from './userTokens';
import shop from './shop';
import subscriptions from './subscriptions';
import adminSubscriptions from './adminSubscriptions';
import questionDatabase from './questionDatabase';
import modules from './modules';
import siderReducer from '../redux/sider/reducers';
import ordersHistoryReducer from '../redux/ordersHistory/reducers';

const authPersistConfig = { key: 'auth', storage };
const themeLayoutConfig = { key: 'themeLayout', storage };
const userConfig = { key: 'user', storage };

export const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, auth),
  themeLayout: persistReducer(themeLayoutConfig, themeLayout),
  user: persistReducer(userConfig, user),
  materials,
  students,
  courses,
  tickets,
  coupons,
  accesscodes,
  crm,
  products,
  cart,
  orders,
  server,
  rewards,
  quiz,
  quizquestions,
  multimedia,
  materialVideos,
  userTokens,
  shop,
  subscriptions,
  adminSubscriptions,
  sider: siderReducer,
  questionDatabase,
  modules,
  ordersHistoryReducer,
  warnings,
  ratings,
});

const composeEnhancers = composeWithDevTools({ name: 'COURSE' });

const store = createStore(rootReducer, undefined, composeEnhancers(applyMiddleware(thunk)));

const configureStore = () => {
  const persistor = persistStore(store);
  return { persistor, store };
};

export const { store: CourseStore, persistor: CoursePersistor } = configureStore();

export default configureStore;
