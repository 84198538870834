import React from 'react';
import { Layout } from 'antd';

const AnnouncementHeader = () => {
  const { Header } = Layout;

  return (
    <Header
      className="page-no-subscription-header"
      id="page-no-subscription-header"
      style={{
        position: 'fixed',
        width: '100%',
        top: '50px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
        margin: '0px',
      }}
    >
      <div className="no-subscription-header-text">
        <>
          <span className="bold-no-sub-header-text">Nowa edycja kursu!</span>{' '}
          <span style={{ fontWeight: '400' }}>Informację jak otrzymać dostęp wysłaliśmy na Twojego maila.</span>
        </>
      </div>
    </Header>
  );
};

export default AnnouncementHeader;
