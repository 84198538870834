/* eslint-disable import/no-cycle */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import API from '../services';

const initialState = {
  tickets: [],
  ticketDetails: [],
  isLoading: false,
  ticketComments: [],
  ticketStatuses: [],
  userTickets: [],
};

export const getTickets = createAsyncThunk('tickets/getTickets', async () => {
  return API.TicketsService.getTickets();
});

export const addTicket = createAsyncThunk('tickets/addTicket', async payload => {
  return API.TicketsService.addTicket(payload);
});

export const adminAddTicket = createAsyncThunk('tickets/adminAddTicket', async payload => {
  return API.TicketsService.adminAddTicket(payload);
});

export const getTicketDetails = createAsyncThunk('tickets/getTicketDetails', async ticketId => {
  return API.TicketsService.getTicketDetails(ticketId);
});

export const getTicketComments = createAsyncThunk('tickets/getTicketComments', async ticketId => {
  return API.TicketsService.getTicketComments(ticketId);
});

export const getTicketStatuses = createAsyncThunk('tickets/getTicketStatuses', async ticketId => {
  return API.TicketsService.getTicketStatuses(ticketId);
});

export const addCommentToTicket = createAsyncThunk('tickets/addCommentToTicket', async payload => {
  return API.TicketsService.addCommentToTicket(payload);
});

export const changeTicketStatus = createAsyncThunk('tickets/changeTicketStatus', async payload => {
  return API.TicketsService.changeTicketStatus(payload);
});

export const getStudentTickets = createAsyncThunk('tickets/getStudentTickets', async userID => {
  return API.TicketsService.getStudentTickets(userID);
});

const ticketsSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {},
  extraReducers: {
    [getTickets.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getTickets.rejected.toString()]: state => {
      state.isLoading = true;
    },
    [getTickets.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.tickets = action.payload.data;
    },
    [addTicket.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się dodać zgłoszenia!',
        description: `${
          action.error?.message === 'Too many opend tickets'
            ? 'Zbyt dużo otwartych zgłoszeń'
            : action.error?.message === 'Wrong subjectId'
            ? 'Niepoprawny identyfikator'
            : action.error?.message === 'Validation failed'
            ? 'Za krótki tytuł lub treść zgłoszenia'
            : action.error?.message
        }`,
        placement: 'bottomRight',
      });
    },
    [addTicket.fulfilled.toString()]: () => {
      notification.success({
        message: `Dodano nowe zgłoszenie!`,
        description: 'Zgłoszenie zostało pomyślnie dodane',
        placement: 'bottomRight',
      });
    },
    [getTicketDetails.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getTicketDetails.rejected.toString()]: state => {
      state.isLoading = true;
    },
    [getTicketDetails.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.ticketDetails = action.payload.data;
    },
    [getTicketComments.fulfilled.toString()]: (state, action) => {
      state.ticketComments = action.payload.data.map(comment => {
        return { ...comment, tag: 'comment' };
      });
    },
    [getTicketStatuses.fulfilled.toString()]: (state, action) => {
      state.ticketStatuses = action.payload.data.map(status => {
        return { ...status, tag: 'status' };
      });
    },

    [addCommentToTicket.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się dodać komentarza do zgłoszenia!',
        description: `${
          action.error.message === 'No permission to comment this ticket'
            ? 'Brak możliwości dodania komentarza do tego zgłoszenia'
            : action.error.message === 'Wrong user'
            ? 'Błąd związany z kontem użytkownika'
            : action.error.message === 'Wrong ticketId'
            ? 'Niepoprawny identyfikator zgłoszenia'
            : action.error.message
        }`,
        placement: 'bottomRight',
      });
    },
    [addCommentToTicket.fulfilled.toString()]: () => {
      notification.success({
        message: `Dodano komentarz do zgłoszenia!`,
        description: 'Komentarz został pomyślnie dodany do zgłoszenia.',
        placement: 'bottomRight',
      });
    },
    [changeTicketStatus.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się zmienić statusu zgłoszenia!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [changeTicketStatus.fulfilled.toString()]: () => {
      notification.success({
        message: `Zmieniono status zgłoszenia!`,
        description: 'Status zgłoszenia został pomyślnie zmieniony.',
        placement: 'bottomRight',
      });
    },
    [getStudentTickets.fulfilled.toString()]: (state, action) => {
      state.userTickets = action.payload.data;
    },
    [getStudentTickets.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się pobrać zgłoszeń użytkownika!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [adminAddTicket.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się dodać zgłoszenia!',
        description: `${
          action.error.message === 'Too many opend tickets'
            ? 'Zbyt dużo otwartych zgłoszeń'
            : action.error.message === 'Wrong subjectId'
            ? 'Niepoprawny identyfikator'
            : action.error.message === 'Validation failed'
            ? 'Za krótki tytuł lub treść zgłoszenia'
            : action.error.message
        }`,
        placement: 'bottomRight',
      });
    },
    [adminAddTicket.fulfilled.toString()]: () => {
      notification.success({
        message: `Dodano nowe zgłoszenie!`,
        description: 'Zgłoszenie zostało pomyślnie dodane',
        placement: 'bottomRight',
      });
    },
  },
});

export default ticketsSlice.reducer;
