import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const StudentsCarts = lazy(() => import('../container/cart/'));
const Cart = lazy(() => import('../container/cart/overview/UserCart'));
const OrderWizard = lazy(() => import('../container/cart/overview/OrderWizard'));

const CartRoutes = () => {
  const { path } = useRouteMatch();
  const { user } = useSelector(state => state.user);
  return (
    <Switch>
      <Route
        exact
        path={path}
        component={
          user.role === 'ADMIN' || user.role === 'TEACHER' || user.role === 'SENIOR-TEACHER' ? StudentsCarts : Cart
        }
      />
      <Route exact path={`${path}/checkout`} component={OrderWizard} />
      <Route
        path={`${path}/checkout/:orderId/successed`}
        render={({ match }) => <Redirect to={`/shop/checkout/${match.params.orderId}/successed`} />}
      />
      <Route
        path={`${path}/checkout/:orderId/failed`}
        render={({ match }) => <Redirect to={`/shop/checkout/${match.params.orderId}/failed`} />}
      />
      <Route path={`${path}/checkout/:orderId/finish`} component={OrderWizard} />
      <Route path={`${path}/checkout/:orderId`} component={OrderWizard} />
    </Switch>
  );
};

export default CartRoutes;
